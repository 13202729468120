import React, { useState } from 'react';
import { Link } from 'gatsby';

import { navigation, navLink, activeNavLink, hamburger, close, hidden } from './navigation.module.scss';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMobileNavigation = () => setIsOpen(!isOpen ? true : false);

  return (
    <React.Fragment>
      <nav className={`${!isOpen ? hidden : ''} ${navigation}`}>
        <ul>
          <li>
            <Link
              to="/about"
              className={navLink}
              activeClassName={activeNavLink}
              onClick={toggleMobileNavigation}
            >About</Link>
          </li>
          <li>
            <Link
              to="/services"
              className={navLink}
              activeClassName={activeNavLink}
              onClick={toggleMobileNavigation}
            >Services</Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={navLink}
              activeClassName={activeNavLink}
              onClick={toggleMobileNavigation}
            >Contact</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link
              to="/careers"
              className={navLink}
              activeClassName={activeNavLink}
              onClick={toggleMobileNavigation}
            >Careers</Link>
          </li>
          <li>
            <Link
              to="/blog"
              className={navLink}
              activeClassName={activeNavLink}
              onClick={toggleMobileNavigation}
            >Blog</Link>
          </li>
          <li>
            <Link
              to="/faq"
              className={navLink}
              activeClassName={activeNavLink}
              onClick={toggleMobileNavigation}
            >FAQ’s</Link>
          </li>
        </ul>
        <button onClick={toggleMobileNavigation} className={close}>X</button>
      </nav>
      <button onClick={toggleMobileNavigation} className={`${isOpen ? hidden : ''} ${hamburger}`}>&#9776;</button>
    </React.Fragment>
  );
};

export default Navigation;